import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import EventService from '../../services/eventService';
import UserService from '../../services/userService';
import { EventDTO, Event } from '../../types/Event';
import theme from '../../theme';
import { Chip, IconButton, Typography, FormControlLabel, Checkbox, MenuItem, Switch, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import FullScreenLoader from '../loader/FullScreenLoader';

// Configure dayjs plugins
dayjs.extend(utc);

type EventFormProps = {
    event?: Event | null;
    loading: boolean
}

const EventForm: React.FC<EventFormProps> = ({ event, loading }) => {
    const libraries: ('places')[] = ['places'];
    const [title, setTitle] = useState('');
    const [customTitle, setCustomTitle] = useState('');
    const [location, setLocation] = useState('');
    const [date, setDate] = useState<Dayjs | null>(dayjs());
    const [keywords, setKeywords] = useState<string[]>(['']);
    const [error, setError] = useState('');
    const [locationError, setLocationError] = useState('');
    const [hostId, setHostId] = useState('');
    const [isAutocompleteUsed, setIsAutocompleteUsed] = useState(false);
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [isRecurring, setIsRecurring] = useState(false);
    const [recurringDay, setRecurringDay] = useState<number | null>(null);
    const [needsAdminAttention, setNeedsAdminAttention] = useState(false);
    const [description, setDescription] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [isRegenerating, setIsRegenerating] = useState(false);
    const [seoKeywords, setSeoKeywords] = useState<string[]>(event?.seoKeywords || []);
    const [isRegeneratingKeywords, setIsRegeneratingKeywords] = useState(false);

    const navigate = useNavigate();
    const eventService = EventService();
    const userService = UserService();

    useEffect(() => {
        if (event) {
            setTitle(event.title || '');
            setCustomTitle(event.customTitle || '');
            setLocation(event.location);
            setDate(dayjs(event.date).utc());
            setKeywords(event.keywords || []);
            setIsAutocompleteUsed(true);
            setLatitude(event.latitude);
            setLongitude(event.longitude);
            setIsRecurring(event.recurringDay !== null);
            setRecurringDay(event.recurringDay);
            setNeedsAdminAttention(event.needsAdminAttention || false);
            setDescription(event.description || '');
            setSeoDescription(event.seoDescription || '');
            setSeoKeywords(event.seoKeywords || []);
        }
    }, [event]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
        libraries: libraries
    });

    useEffect(() => {
        if (loadError) {
            console.error('Error loading Google Maps API:', loadError);
        }
    }, [loadError]);

    useEffect(() => {
        const fetchUser = async () => {
            const user = await userService.getMe();
            if (user) {
                setHostId(user.id);
            }
        };
        fetchUser();
    }, []);

    const onLoad = useCallback((autocomplete: google.maps.places.Autocomplete) => {
        setAutocomplete(autocomplete);
    }, []);

    const handleKeywordChange = (index: number, value: string) => {
        const newKeywords = [...keywords];
        newKeywords[index] = value;
        setKeywords(newKeywords);
    };

    const addKeyword = () => {
        setKeywords([...keywords, '']);
    };

    const removeKeyword = (index: number) => {
        const newKeywords = keywords.filter((_, i) => i !== index);
        setKeywords(newKeywords);
    };

    const onPlaceChanged = () => {
        setLocationError('');
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setLocation(place.formatted_address || '');
                setLatitude(lat);
                setLongitude(lng);
                setIsAutocompleteUsed(true);
            } else {
                setLocation(place.name || '');
                setLatitude(null);
                setLongitude(null);
                setIsAutocompleteUsed(false);
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!location || !date || !hostId) {
            setError('Vul alle verplichte velden in');
            return;
        }
        if (!isAutocompleteUsed) {
            setError('Gebruik een geldig adres voor de locatie.');
            return;
        }

        const eventData: EventDTO = {
            title,
            customTitle,
            location,
            date: date ? date.utc().format() : '',
            host: { id: hostId },
            keywords: keywords.filter(keyword => keyword.trim() !== ''),
            latitude,
            longitude,
            recurringDay: recurringDay,
            needsAdminAttention,
            seoDescription,
            seoKeywords: seoKeywords,
        };

        try {
            if (event) {
                await eventService.updateEvent(event.id, eventData);
            } else {
                await eventService.createEvent(eventData);
            }
            navigate('/events');
        } catch (error) {
            console.error('Failed to save event:', error);
            setError('Failed to save event. Please try again.');
        }
    };

    const adminAttentionToggle = (
        <FormControlLabel
            control={
                <Switch
                    checked={needsAdminAttention}
                    onChange={(e) => setNeedsAdminAttention(e.target.checked)}
                    color="warning"
                />
            }
            label="Needs Admin Attention"
            sx={{
                width: '100%',
                marginTop: 2
            }}
        />
    );

    const handleRegenerateSeoDescription = async () => {
        if (!event?.id) return;

        try {
            setIsRegenerating(true);
            const newDescription = await eventService.regenerateSeoDescription(event.id);
            if (newDescription) {
                setSeoDescription(newDescription);
            }
        } catch (error) {
            console.error('Failed to regenerate SEO description:', error);
        } finally {
            setIsRegenerating(false);
        }
    };

    const handleRegenerateSeoKeywords = async () => {
        if (!event?.id) return;

        try {
            setIsRegeneratingKeywords(true);
            const newKeywords = await eventService.regenerateSeoKeywords(event.id);
            if (newKeywords) {
                setSeoKeywords(newKeywords);
            }
        } catch (error) {
            console.error('Failed to regenerate SEO keywords:', error);
        } finally {
            setIsRegeneratingKeywords(false);
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 300,
                margin: '0 auto',
                padding: 4,
                backgroundColor: theme.palette.background.default,
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                color: theme.palette.text.primary,
            })}
        >
            {loading && <FullScreenLoader />}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {locationError && <p style={{ color: 'red' }}>{locationError}</p>}
            <TextField
                required
                label="Titel"
                variant="outlined"
                fullWidth
                margin="normal"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                InputProps={{
                    style: { color: theme.palette.text.primary, borderColor: theme.palette.text.primary },
                    sx: {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.primary.main,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.text.primary,
                        },
                    },
                }}
            />
            <TextField
                label="Aangepaste titel (optioneel)"
                variant="outlined"
                fullWidth
                margin="normal"
                value={customTitle}
                onChange={(e) => setCustomTitle(e.target.value)}
                InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                InputProps={{
                    style: { color: theme.palette.text.primary, borderColor: theme.palette.text.primary },
                    sx: {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.primary.main,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.text.primary,
                        },
                    },
                }}
            />
            {isLoaded && !loadError ? (
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                        options={{
                            types: ['address'],
                            componentRestrictions: { country: 'nl' }, // Restrict to Netherlands, change if needed
                            fields: ['address_components', 'formatted_address', 'name', 'geometry'],
                        }}
                    >
                        <TextField
                            label="Locatie"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                            InputProps={{
                                style: { color: theme.palette.text.primary, borderColor: theme.palette.text.primary },
                                sx: {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.text.primary,
                                    },
                                },
                            }}
                        />
                    </Autocomplete>
                </Box>
            ) : (
                <TextField
                    label="Locatie"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                    InputProps={{
                        style: { color: theme.palette.text.primary, borderColor: theme.palette.text.primary },
                        sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.primary.main,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.primary,
                            },
                        },
                    }}
                />
            )}
            {description && <Box sx={{ width: '100%', textAlign: 'left' }}>
                <Typography variant="h6" sx={{ width: '100%', textAlign: 'left' }}>Originele beschrijving</Typography>
                <Typography variant="body1" sx={{ width: '100%', textAlign: 'left' }}>{description}</Typography>
            </Box>}
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <TextField
                    label="SEO Beschrijving"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={seoDescription}
                    onChange={(e) => setSeoDescription(e.target.value)}
                    multiline
                    rows={8}
                    InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                    InputProps={{
                        style: { color: theme.palette.text.primary, borderColor: theme.palette.text.primary },
                        sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.primary.main,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.primary,
                            },
                        },
                    }}
                />
                {event && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleRegenerateSeoDescription}
                        disabled={isRegenerating}
                        sx={{ mt: 2, minWidth: '120px', height: '40px' }}
                    >
                        {isRegenerating ? (
                            <CircularProgress size={24} />
                        ) : (
                            'Regenereer'
                        )}
                    </Button>
                )}
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                    SEO Keywords
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {seoKeywords.map((keyword, index) => (
                        <Chip
                            key={index}
                            label={keyword}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'primary.contrastText',
                            }}
                        />
                    ))}
                </Box>
                {event && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleRegenerateSeoKeywords}
                        disabled={isRegeneratingKeywords}
                        sx={{ mt: 2, minWidth: '120px', height: '40px' }}
                    >
                        {isRegeneratingKeywords ? (
                            <CircularProgress size={24} />
                        ) : (
                            'Regenereer Keywords'
                        )}
                    </Button>
                )}
            </Box>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isRecurring}
                        onChange={(e) => setIsRecurring(e.target.checked)}
                    />
                }
                label="Wekelijks event"
                sx={{
                    width: '100%'
                }}
            />

            {isRecurring && (
                <TextField
                    select
                    label="Elke"
                    value={recurringDay}
                    onChange={(e) => setRecurringDay(Number(e.target.value))}
                    fullWidth
                    margin="normal"
                >
                    {[0, 1, 2, 3, 4, 5, 6].map((day) => (
                        <MenuItem key={day} value={day}>
                            {['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'][day]}
                        </MenuItem>
                    ))}
                </TextField>
            )}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    sx={{ width: '100%', marginTop: '16px', marginBottom: '8px' }}
                    format="DD/MM/YYYY HH:mm"
                    ampm={false}
                    label={isRecurring ? 'Startdatum' : 'Datum'}
                    value={date}
                    onChange={(newValue) => {
                        if (newValue) {
                            // Force timezone to UTC to prevent automatic conversion
                            const utcDate = newValue.utc(true);
                            setDate(utcDate);
                        } else {
                            setDate(null);
                        }
                    }}
                    timezone="UTC"
                    closeOnSelect
                />
            </LocalizationProvider>
            <Typography variant="h6" sx={{ marginTop: '10px', width: '100%', textAlign: 'left' }}>Tags</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2, width: '100%', alignItems: 'center' }}>
                {keywords.map((keyword, index) => (
                    <Chip
                        key={index}
                        label={
                            <TextField
                                value={keyword}
                                onChange={(e) => handleKeywordChange(index, e.target.value)}
                                variant="standard"
                                InputProps={{ disableUnderline: true }}
                                style={{ width: '100%', padding: '0 4px' }}
                            />
                        }
                        onDelete={() => removeKeyword(index)}
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            margin: '2px',
                            maxWidth: '100px',
                        }}
                    />
                ))}
                <IconButton onClick={addKeyword} color="primary" size="small">
                    <AddIcon fontSize="small" />
                </IconButton>
            </Box>
            {adminAttentionToggle}
            <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                sx={{
                    mt: 2,
                    width: '100%',
                }}
            >
                {event ? 'Bijwerken' : 'Aanmaken'}
            </Button>
        </Box >
    );
};

export default EventForm;
