import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import EventForm from '../components/event-form/EventForm';
import CloseIcon from '@mui/icons-material/Close';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Event } from '../types/Event';
import EventService from '../services/eventService';
import UserService from '../services/userService';
import { useStytchUser } from '@stytch/react';
import FullScreenLoader from '../components/loader/FullScreenLoader';

const CreateEvent = () => {
    const navigate = useNavigate();
    const { meetId } = useParams<{ meetId: string }>();
    const [event, setEvent] = useState<Event | null>(null);
    const eventService = EventService();
    const userService = UserService();
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
    const { user: stytchUser } = useStytchUser();

    useEffect(() => {
        if (meetId) {
            eventService.getEvent(meetId).then(setEvent).finally(() => setLoading(false));
        }
    }, [meetId]);

    useEffect(() => {
        const checkAdminStatus = async () => {
            if (stytchUser) {
                const user = await userService.getMe();
                setIsAdmin(user?.isAdmin || false);
            } else {
                setIsAdmin(false);
            }
            setLoading(false);
        };
        checkAdminStatus();
    }, [stytchUser]);

    if (loading || isAdmin === null) {
        return <FullScreenLoader />;
    }

    if (isAdmin === false) {
        return <Navigate to="/events" />;
    }

    return (
        <Box sx={{
            '@media (min-width: 768px)': {
                display: 'flex',
                flexDirection: 'row-reverse',
                width: '100%'
            }
        }}>
            <>
                <IconButton
                    onClick={() => navigate('/events')}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: 2,
                    }}>
                    <CloseIcon />
                </IconButton>
                <Box
                    sx={(theme) => ({
                        display: 'flex',
                        marginTop: '72px',
                        flexDirection: 'column',
                        width: '100%',
                        backgroundColor: theme.palette.background.default,
                        color: theme.palette.text.primary,
                    })}
                >
                    <Typography variant="h4" align='center'>
                        {meetId ? 'Bewerk meet' : 'Nieuwe meet'}
                    </Typography>
                    {event?.title && <Typography variant="h6" align='center'>{event.title}</Typography>}
                    <EventForm event={event} loading={loading} />
                </Box>
            </>
        </Box>
    );
};

export default CreateEvent;