import React from 'react';
import { Box, Typography } from '@mui/material';

const RatingDisplay = ({ averageRating, size = 'small' }: { averageRating: number, size?: 'small' | 'medium' }) => (
    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        backgroundColor: 'rgba(28, 28, 28, 0.8)',
        borderRadius: '100px',
        padding: '4px 8px',
        width: 'fit-content'
    }}>
        <Typography
            component="span"
            variant="body2"
            sx={{
                opacity: averageRating < 1 ? 0.3 : 1,
                fontSize: size === 'small' ? '12pt' : '14pt'
            }}
        >
            ⭐️
        </Typography>
        <Typography
            variant="body2"
            color="text.secondary"
            sx={{
                fontSize: size === 'small' ? '12pt' : '14pt'
            }}
        >
            {averageRating >= 1
                ? averageRating.toFixed(1).replace('.', ',')
                : '-'
            }
        </Typography>
    </Box>
);

export default RatingDisplay;
