import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, CircularProgress } from '@mui/material';

interface DeleteConfirmationProps {
    type: 'meet' | 'review';
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isLoading?: boolean;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({ type, open, onClose, onConfirm, isLoading = false }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box sx={{ backgroundColor: 'background.default' }}>
                <DialogTitle id="alert-dialog-title">{`Weet je zeker dat je deze ${type} wilt verwijderen?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="text.secondary">
                        Deze actie kan niet ongedaan worden gemaakt.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                        onClick={onClose}
                        color="secondary"
                        variant="outlined"
                        fullWidth
                        disabled={isLoading}
                    >
                        Annuleren
                    </Button>
                    <Button
                        onClick={onConfirm}
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled={isLoading}
                        sx={{ position: 'relative', minHeight: '42px' }}
                    >
                        {isLoading ? (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: 'white',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px'
                                }}
                            />
                        ) : (
                            'OK'
                        )}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default DeleteConfirmation;
