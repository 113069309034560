import React from 'react';
import { JsonLd } from 'react-schemaorg';
import { Event, WithContext } from 'schema-dts';

interface EventSchemaProps {
    title: string;
    description?: string;
    startDate: string;
    location: string;
    latitude?: number | null;
    longitude?: number | null;
    keywords?: string[];
    recurringDay?: number | null;
}

const EventSchema: React.FC<EventSchemaProps> = ({
    title,
    description,
    startDate,
    location,
    latitude,
    longitude,
    keywords,
    recurringDay
}) => {
    const schema: WithContext<Event> = {
        '@type': 'Event',
        '@context': 'https://schema.org',
        name: title,
        description: description || undefined,
        startDate: recurringDay ?
            (() => {
                const today = new Date();
                const dayOfWeek = recurringDay;
                const diff = (dayOfWeek + 7 - today.getDay()) % 7;
                const nextDate = new Date(today);
                nextDate.setDate(today.getDate() + diff);
                return nextDate.toISOString();
            })() :
            startDate,
        location: {
            '@type': 'Place',
            name: location,
            ...(latitude && longitude && {
                geo: {
                    '@type': 'GeoCoordinates',
                    latitude,
                    longitude
                }
            })
        },
        ...(keywords?.length && { keywords: keywords.join(', ') }),
        ...(recurringDay !== null && recurringDay !== undefined && {
            eventSchedule: {
                '@type': 'Schedule',
                byDay: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'][recurringDay],
                repeatFrequency: 'P1W'
            }
        })
    };

    return <JsonLd<Event> item={schema} />;
};

export default EventSchema; 