import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import { Event } from '../../types/Event';

type FilterBarProps = {
    events: Event[];
    showFavorites: boolean;
    favorites: string[];
    onFilterChange: (selectedKeywords: string[]) => void;
    selectedKeywords: string[];
}

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const FilterBar = ({ onFilterChange, events, showFavorites, favorites, selectedKeywords }: FilterBarProps) => {
    const [showFade, setShowFade] = useState(false);
    const scrollRef = React.useRef<HTMLDivElement>(null);

    const keywords = useMemo(() => {
        const keywordCounts = events.filter(event => showFavorites ? favorites.includes(event.id) : true).reduce((acc, event) => {
            event.keywords.forEach(keyword => {
                acc[keyword] = (acc[keyword] || 0) + 1;
            });
            return acc;
        }, {} as Record<string, number>);

        return Object.entries(keywordCounts)
            .sort((a, b) => b[1] - a[1])
            .map(([keyword]) => keyword);
    }, [events, showFavorites, favorites]);

    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollWidth, scrollLeft, clientWidth } = scrollRef.current;
            const isAtEnd = Math.ceil(scrollLeft + clientWidth) >= scrollWidth;
            setShowFade(!isAtEnd);
        }
    };

    useEffect(() => {
        const scrollElement = scrollRef.current;
        if (scrollElement) {
            handleScroll(); // Check initial state
            scrollElement.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollElement) {
                scrollElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const handleKeywordClick = (keyword: string) => {
        if (selectedKeywords.includes(keyword)) {
            onFilterChange(selectedKeywords.filter(kw => kw !== keyword));
        } else {
            onFilterChange([...selectedKeywords, keyword]);
        }
    };

    return (
        <Box sx={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
            <Box
                ref={scrollRef}
                sx={{
                    display: 'flex',
                    gap: 1,
                    p: 2,
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    position: 'relative',
                    maskImage: showFade ? 'linear-gradient(to right, black 85%, transparent 100%)' : 'none',
                    WebkitMaskImage: showFade ? 'linear-gradient(to right, black 85%, transparent 100%)' : 'none',
                }}
            >
                {keywords.map((keyword) => (
                    <Button
                        key={keyword}
                        onClick={() => handleKeywordClick(keyword)}
                        size="large"
                        variant={selectedKeywords.includes(keyword) ? 'contained' : 'outlined'}
                        color={selectedKeywords.includes(keyword) ? 'primary' : 'secondary'}
                        sx={{
                            height: '32px',
                            minWidth: 'auto',
                            whiteSpace: 'nowrap',
                            mr: 0,
                            mb: 0,
                            flexShrink: 0,
                            '&:focus': {
                                outline: 'none',
                                boxShadow: 'none',
                            },
                            '&.MuiButton-outlined': {
                                borderColor: (theme) => theme.palette.primary.main,
                            },
                            '&.MuiButton-outlined:hover, &.MuiButton-outlined:focus': {
                                borderColor: (theme) => theme.palette.primary.main,
                            },
                        }}
                    >
                        {capitalizeFirstLetter(keyword)}
                    </Button>
                ))}
            </Box>
        </Box>
    );
};

export default FilterBar;