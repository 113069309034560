import { useStytch } from '@stytch/react';
import { Review, ReviewDTO } from '../types/Review';

type ReviewServiceType = {
    createReview: (review: ReviewDTO) => Promise<Review>;
    getReviewsByMeetId: (meetId: string) => Promise<Review[]>;
    deleteReview: (reviewId: string) => Promise<void>;
};

const ReviewService = (): ReviewServiceType => {
    const stytch = useStytch();

    const createReview = async (review: ReviewDTO): Promise<Review> => {
        try {
            const formData = new FormData();
            formData.append('rating', String(review.rating));
            if (review.comment) formData.append('comment', review.comment);
            formData.append('meetId', review.meetId);

            if (review.images && review.images.length > 0) {
                review.images.forEach(image => {
                    if (image instanceof File) {
                        formData.append('images', image);
                    }
                });
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reviews`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error creating review:', error);
            throw error;
        }
    };

    const getReviewsByMeetId = async (meetId: string): Promise<Review[]> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reviews/${meetId}`, {
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching reviews:', error);
            return [];
        }
    };

    const deleteReview = async (reviewId: string): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reviews/${reviewId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error deleting review:', error);
            throw error;
        }
    };

    return {
        createReview,
        getReviewsByMeetId,
        deleteReview,
    };
};

export default ReviewService; 