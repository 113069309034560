import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    Box,
    Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface CookieSettings {
    necessary: boolean;
    analytics: boolean;
    marketing: boolean;
}

const CookieConsent: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [settings, setSettings] = useState<CookieSettings>({
        necessary: true,
        analytics: false,
        marketing: false
    });

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setOpen(true);
        }
    }, []);

    const handleAcceptAll = () => {
        const allSettings = {
            necessary: true,
            analytics: true,
            marketing: true
        };
        saveSettings(allSettings);
    };

    const handleSaveSettings = () => {
        saveSettings(settings);
    };

    const saveSettings = (selectedSettings: CookieSettings) => {
        localStorage.setItem('cookieConsent', JSON.stringify({
            ...selectedSettings,
            timestamp: new Date().toISOString()
        }));
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettings({
            ...settings,
            [event.target.name]: event.target.checked
        });
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="cookie-consent-title"
            maxWidth="sm"
            fullWidth
        >
            <Box sx={{ backgroundColor: 'background.paper' }}>
                <DialogTitle
                    id="cookie-consent-title"
                    sx={{
                        fontSize: '1.2rem',
                        fontWeight: 700,
                        mb: 2
                    }}
                >
                    Cookie-instellingen
                </DialogTitle>
                <DialogContent>
                    <Typography paragraph>
                        Wij gebruiken cookies om je gebruikservaring te verbeteren. Je kunt hieronder je voorkeuren instellen.
                        Lees ons <Link component={RouterLink} to="/privacy-statement">privacybeleid</Link> voor meer informatie.
                    </Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={settings.necessary}
                                    disabled
                                    name="necessary"
                                />
                            }
                            label={
                                <Box>
                                    <Typography variant="body1">Noodzakelijke cookies</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Deze cookies zijn nodig voor het functioneren van de website.
                                    </Typography>
                                </Box>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={settings.analytics}
                                    onChange={handleChange}
                                    name="analytics"
                                />
                            }
                            label={
                                <Box>
                                    <Typography variant="body1">Analytische cookies</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Helpen ons te begrijpen hoe bezoekers onze website gebruiken.
                                    </Typography>
                                </Box>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={settings.marketing}
                                    onChange={handleChange}
                                    name="marketing"
                                />
                            }
                            label={
                                <Box>
                                    <Typography variant="body1">Marketing cookies</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Worden gebruikt voor gepersonaliseerde advertenties.
                                    </Typography>
                                </Box>
                            }
                        />
                    </FormGroup>
                </DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, m: 2 }}>
                    <Button
                        onClick={handleSaveSettings}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                    >
                        Opslaan
                    </Button>
                    <Button
                        onClick={handleAcceptAll}
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ m: 0 }}
                    >
                        Alle cookies accepteren
                    </Button>
                </ Box>
            </Box>
        </Dialog >
    );
};

export default CookieConsent;
