import { createTheme } from '@mui/material/styles';

// Color constants
const DARK_GREY = '#3B4851';
const WHITE = '#ffffff';
const DARK_BACKGROUND = '#111518';
const PAPER_BACKGROUND = '#1C2226';
const LIGHT_GREY = '#D9E7F1';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: DARK_GREY,
        },
        secondary: {
            main: WHITE,
        },
        background: {
            default: DARK_BACKGROUND,
            paper: PAPER_BACKGROUND,
        },
        text: {
            primary: WHITE,
            secondary: LIGHT_GREY,
        },
    },
    typography: {
        fontFamily: 'Barlow, Arial, sans-serif',
        fontSize: 16, // Increase the base font size (default is 14)
        h6: {
            fontSize: '1.2rem', 
        },
        h4: {
            fontSize: '2.125rem', // Slightly larger h4 (default is 2.125rem)
        },
        body1: {
            fontSize: '0.95rem', // Slightly larger body text (default is 1rem)
        },
        body2: {
            fontSize: '0.9rem', // Slightly larger secondary text (default is 0.875rem)
        },
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    fontSize: '16pt'
                }
            }
        },

        MuiLink: {
            styleOverrides: {
                root: {
                    color: WHITE,
                    '&:hover': {
                        color: WHITE,
                    },
                    textDecorationColor: LIGHT_GREY,
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    textTransform: 'none',
                    fontWeight: 700,
                    fontSize: '1rem', // Slightly larger button text
                },
            },
            variants: [
                {
                    props: { size: 'small' },
                    style: {
                        padding: '4px 8px',
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        padding: '6px 16px',
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        padding: '8px 22px',
                    },
                },
            ],
        },

        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked:not(.Mui-disabled)': {
                        color: WHITE,
                    },
                },
            },
        },

        MuiRating: {
            styleOverrides: {
                iconEmpty: {
                    color: WHITE,
                    opacity: 0.3,
                },
            },
        },
    },
});

export default theme;
