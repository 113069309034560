import React, { useState, useCallback, useEffect } from 'react';
import { Button, debounce, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useStytchUser } from '@stytch/react';
import AuthModal from '../auth-modal/AuthModal';
import EventService from '../../services/eventService';

type AttendButtonProps = {
    meetId: string;
    isAttending: boolean;
    attendeesCount: number;
    onAttendChange: (isAttending: boolean, newAttendeesCount: number) => void;
    loading?: boolean;
    eventDate: Date;
}

const AttendButton: React.FC<AttendButtonProps> = ({ meetId, isAttending, attendeesCount, onAttendChange, loading, eventDate }) => {
    const [isAttended, setIsAttended] = useState(isAttending);
    const [animateAttend, setAnimateAttend] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);
    const { user: stytchUser } = useStytchUser();
    const eventService = EventService();

    // Add useEffect to sync with parent's isAttending prop
    useEffect(() => {
        setIsAttended(isAttending);
    }, [isAttending, stytchUser]);

    useEffect(() => {
        setIsProcessing(loading || false);
    }, [loading]);

    // Debounced version of the attendance update
    const debouncedAttendanceUpdate = useCallback(
        debounce(async (shouldAttend: boolean) => {
            try {
                if (shouldAttend) {
                    await eventService.attendEvent(meetId, eventDate);
                    onAttendChange(true, attendeesCount + 1);
                } else {
                    await eventService.unattendEvent(meetId, eventDate);
                    onAttendChange(false, attendeesCount - 1);
                }
            } catch (error) {
                setIsAttended(!shouldAttend);
                console.error('Failed to update attendance:', error);
            } finally {
                setIsProcessing(false);
            }
        }, 500),
        [meetId, attendeesCount, eventDate]
    );

    const handleAttendClick = async () => {
        if (stytchUser) {
            if (isProcessing) return;

            setIsProcessing(true);
            setAnimateAttend(false);
            setIsAttended(!isAttended);
            setAnimateAttend(true);

            debouncedAttendanceUpdate(!isAttended);
        } else {
            setShowAuthModal(true);
        }
    };

    return (
        <>
            <Button
                endIcon={!isAttended || !stytchUser ? (
                    <CheckBoxOutlineBlankIcon sx={{ fontSize: '12pt', height: '14pt', width: '14pt' }} />
                ) : (
                    <Typography
                        sx={{
                            fontSize: '12pt',
                            animation: animateAttend ? 'bounceAttend 0.3s' : 'none',
                            '@keyframes bounceAttend': {
                                '0%': { transform: 'perspective(400px) scale(0)' },
                                '50%': { transform: 'perspective(400px) scale(1.5)' },
                                '100%': { transform: 'perspective(400px) scale(1)' },
                            }
                        }}
                    >
                        ✅
                    </Typography>
                )}
                aria-label="attend meet"
                onClick={handleAttendClick}
                variant={isAttended ? 'contained' : 'outlined'}
                color={isAttended ? 'primary' : 'secondary'}
                disabled={isProcessing}
                sx={{
                    minWidth: '140px',
                    alignItems: 'center',
                    height: '38px',
                }}
            >
                <Typography>Aanwezig</Typography>
            </Button>
            <AuthModal
                open={showAuthModal}
                onClose={() => setShowAuthModal(false)}
                message={'Log in om deel te nemen'}
            />
        </>
    );
};

export default AttendButton;