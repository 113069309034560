import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { useStytch, useStytchUser } from '@stytch/react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../theme';
import ImageCarousel from '../components/ImageCarousel';
import { ACTIVITY_TIMEOUT, useSessionService } from '../services/sessionService';

const Login = ({ register, onClose }: { register?: boolean, onClose?: () => void }) => {
    const { user } = useStytchUser();
    const stytch = useStytch();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const eventId = new URLSearchParams(location.search).get('event_id');
    const [isRegister, setIsRegister] = useState(register);
    const [passwordStrength, setPasswordStrength] = useState<'weak' | 'strong' | null>(null);
    const navigate = useNavigate();
    const sessionService = useSessionService();

    const getRedirectUrl = () => {
        const baseUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL || 'http://localhost:3000/';
        return eventId ? `${baseUrl}?event_id=${eventId}` : baseUrl;
    };

    const checkPasswordStrength = (password: string): 'weak' | 'strong' => {
        return password.length >= 8 ? 'strong' : 'weak';
    };

    const handleAuth = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setPasswordStrength(null);

        try {
            if (isRegister) {
                if (password !== confirmPassword) {
                    setError('Wachtwoorden komen niet overeen.');
                    setIsLoading(false);
                    return;
                }
                const response = await stytch.passwords.create({
                    email,
                    password,
                    session_duration_minutes: ACTIVITY_TIMEOUT
                });
                if (response.status_code === 200) {
                    sessionService.startActivityMonitoring();
                    if (onClose) {
                        onClose();
                    } else {
                        window.location.href = getRedirectUrl();
                    }
                }
            } else {
                const response = await stytch.passwords.authenticate({
                    email,
                    password,
                    session_duration_minutes: ACTIVITY_TIMEOUT
                });
                if (response.status_code === 200) {
                    sessionService.startActivityMonitoring();
                    if (onClose) {
                        onClose();
                    } else {
                        window.location.href = getRedirectUrl();
                    }
                }
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error.error_type === 'user_not_found') {
                setError('Gebruiker niet gevonden. Controleer uw e-mailadres of registreer een nieuw account.');
            } else if (error.status_code === 401 || error.error_type === 'unauthorized_credentials') {
                setError('Ongeldige e-mail/wachtwoord combinatie. Probeer het opnieuw.');
            } else if (error.error_type === 'invalid_credentials') {
                setError('Ongeldige e-mail/wachtwoord combinatie. Probeer het opnieuw.');
            } else if (error.error_type === 'invalid_email') {
                setError('Ongeldig e-mailadres. Controleer uw invoer en probeer het opnieuw.');
            } else if (error.status_code === 400 && error.error_message === 'password appears in a list of breached passwords.') {
                setError('Dit wachtwoord is te vaak gebruikt en mogelijk onveilig. Kies een ander wachtwoord.');
            } else if (error.error_type === 'duplicate_email') {
                setError('Er bestaat al een account met dit e-mailadres. Probeer in te loggen of gebruik een ander e-mailadres.');
            } else {
                setError('Er ging iets mis. Probeer het opnieuw.');
            }
            console.error('Login error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            onClose ? onClose() : navigate(eventId ? `/events/${eventId}` : '/events');
        }
    }, [user, navigate, eventId]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            position: 'relative',
            overflow: 'hidden',
            paddingTop: '300px',
        }}>
            <Box sx={{
                position: 'absolute',
                top: '25px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}>
                <ImageCarousel />
            </Box>
            <Box sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                top: '200px',
                '@media (max-width: 768px)': {
                    top: '150px',
                },
                left: 0,
                width: '100%',
                height: 'auto',
                zIndex: 1000,
            }}>
                <Box
                    component="form"
                    onSubmit={handleAuth}
                    sx={{
                        width: '300px',
                        backgroundColor: theme.palette.background.paper,
                        padding: 3,
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <>
                        <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ mb: 2 }}
                            required
                            autoComplete="email"
                            inputProps={{
                                autoCapitalize: 'none',
                                autoCorrect: 'off'
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Wachtwoord"
                            variant="outlined"
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                if (isRegister) {
                                    setPasswordStrength(checkPasswordStrength(e.target.value));
                                } else {
                                    setError('');
                                }
                            }}
                            sx={{ mb: 2 }}
                            required
                        />
                        {(error && !isRegister) && (
                            <Typography
                                sx={{
                                    marginBottom: 2,
                                    textAlign: 'left',
                                    color: 'error.main'
                                }}
                            >
                                {error}
                            </Typography>
                        )}
                        {isRegister && (
                            <>
                                <TextField
                                    fullWidth
                                    label="Bevestig wachtwoord"
                                    variant="outlined"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    sx={{ mb: 0 }}
                                    required
                                />
                                {(error || (isRegister && passwordStrength)) && (
                                    <Typography
                                        sx={{
                                            marginBottom: 2,
                                            textAlign: 'left',
                                            color: isRegister && passwordStrength === 'strong' ? 'text.secondary' : 'error.main'
                                        }}
                                    >
                                        {error || (isRegister && passwordStrength === 'weak' ? 'Wachtwoord moet minimaal 8 tekens lang zijn.' : 'Wachtwoord is lang genoeg.')}
                                    </Typography>
                                )}
                            </>
                        )}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            disabled={isLoading}
                            sx={{ mb: 0 }}
                        >
                            {isLoading ? <CircularProgress size={24} /> : (isRegister ? 'Account aanmaken' : 'Login')}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            fullWidth
                            onClick={() => onClose ? onClose() : navigate('/')}
                            sx={{ mt: 1 }}
                        >
                            Sluiten
                        </Button>
                        <Button
                            onClick={() => setIsRegister(!isRegister)}
                            sx={{ mb: 2, px: 3 }}
                        >
                            <Typography>
                                {isRegister ?
                                    `Heb je al een account?
                                    Login` :
                                    `Heb je nog geen account? 
                                    Registreer`}
                            </Typography>
                        </Button>
                        <Button
                            variant="text"
                            onClick={() => navigate('/reset-password')}
                            sx={{ mt: 1 }}
                        >
                            Wachtwoord vergeten?
                        </Button>
                    </>
                </Box>
            </Box>
        </Box>
    );
};

export default Login;