import React, { useState } from 'react';
import { Box, Typography, Button, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Login from '../../pages/Login';

type AuthModalProps = {
    open: boolean;
    message?: string;
    onClose: () => void;
}

const AuthModal: React.FC<AuthModalProps> = ({ open, onClose, message }) => {
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isRegister, setIsRegister] = useState(false);

    const handleLoginClick = () => {
        setIsRegister(false);
        setShowLoginModal(true);
        onClose();
    };

    const handleRegisterClick = () => {
        setIsRegister(true);
        setShowLoginModal(true);
        onClose();
    };

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="auth-modal-title"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    pt: 1,
                    borderRadius: 2,
                    outline: 'none',
                }}>
                    <Box sx={{ display: 'flex', width: '100%', mb: 3, justifyContent: 'space-between', textAlign: 'center' }}>
                        <Typography id="auth-modal-title" variant="h6" component="h2" textAlign={'left'} gutterBottom>
                            {message || 'Log in of meld je aan'}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon sx={{ fontSize: '16pt' }} />
                        </IconButton>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleLoginClick}
                            fullWidth
                        >
                            Inloggen
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleRegisterClick}
                            fullWidth
                        >
                            Registreren
                        </Button>

                        <Button
                            variant="text"
                            href="mailto:info@carmeeter.com"
                            startIcon={<MailOutlineIcon />}
                            sx={{
                                mt: 2,
                                width: '100%',
                                color: 'text.secondary',
                                borderRadius: 1,
                                padding: '8px 16px',
                                transition: 'all 0.2s ease-in-out',
                            }}
                        >
                            Contact
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={showLoginModal}
                sx={{
                    zIndex: (theme) => theme.zIndex.modal + 1
                }}
            >
                <Box sx={{
                    width: '100%',
                    height: '100%',
                    bgcolor: 'background.default'
                }}>
                    <Login register={isRegister} onClose={() => setShowLoginModal(false)} />
                </Box>
            </Modal>
        </>
    );
};

export default AuthModal;