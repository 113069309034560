import { useStytch } from '@stytch/react';
import { User } from '../types/User';

type UserServiceType = {
    getFavoriteMeets: () => Promise<string[]>;
    addFavoriteMeet: (meetId: string) => Promise<void>;
    removeFavoriteMeet: (meetId: string) => Promise<void>;
    getMe: () => Promise<User | null>;
    generateReferralCode: () => Promise<string>;
    activateReferralCode: (code: string) => Promise<void>;
};

const UserService = (): UserServiceType => {
    const stytch = useStytch();

    const getFavoriteMeets = async (): Promise<string[]> => {
        try {
            const sessionToken = stytch.session.getTokens()?.session_token;
            if (!sessionToken) {
                return [];
            }
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/favorites`, {
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                },
            });
            if (!response.ok) {
                return [];
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('There was a problem fetching favorite meets:', error);
            return [];
        }
    };

    const addFavoriteMeet = async (meetId: string): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/favorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({ meetId }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('There was a problem adding favorite meet:', error);
            throw error;
        }
    };

    const removeFavoriteMeet = async (meetId: string): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/unfavorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({ meetId }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('There was a problem removing favorite meet:', error);
            throw error;
        }
    };

    const getMe = async (): Promise<User | null> => {
        try {
            const sessionToken = stytch.session.getTokens()?.session_token;
            if (!sessionToken) {
                return null;
            }
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/me`, {
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return {
                ...data,
            };
        } catch (error) {
            console.error('There was a problem fetching user:', error);
            return null;
        }
    };

    const generateReferralCode = async (): Promise<string> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/generate-referral`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data.code;
        } catch (error) {
            console.error('There was a problem generating referral code:', error);
            throw error;
        }
    };

    const activateReferralCode = async (code: string): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/activate-referral`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({ code }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('There was a problem activating referral code:', error);
            throw error;
        }
    };

    return {
        getFavoriteMeets,
        addFavoriteMeet,
        removeFavoriteMeet,
        getMe,
        generateReferralCode,
        activateReferralCode,
    };
};

export default UserService;
