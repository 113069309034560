import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <Box
            component="footer"
            sx={{
                width: '100%',
                padding: 2,
                backgroundColor: 'background.paper',
                borderTop: '1px solid',
                borderColor: 'divider',
                marginTop: 'auto',
                textAlign: 'center',
            }}
        >
            <Box sx={{ maxWidth: '600px', margin: '0 auto' }}>
                <Typography variant="body2" color="text.secondary" paragraph>
                    CarMeeter is een platform voor het delen van auto-evenementen. Wij zijn niet verantwoordelijk voor de inhoud van de evenementen.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 1 }}>
                    <Link component={RouterLink} to="/privacy-statement" color="primary" underline="hover">
                        Privacy Statement
                    </Link>
                    <Link component={RouterLink} to="/algemene-voorwaarden" color="primary" underline="hover">
                        Algemene Voorwaarden
                    </Link>
                </Box>
                <Typography variant="body2" color="text.secondary">
                    © {currentYear} CarMeeter. Alle rechten voorbehouden.
                </Typography>
            </Box>
        </Box>
    );
};

export default Footer; 