import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import Search from '../search/Search';
import { useLocation } from 'react-router-dom';
import ProfileModal from '../profile-modal/ProfileModal';
import AuthModal from '../auth-modal/AuthModal';
import { useStytchUser } from '@stytch/react';
import TabBar from '../tab-bar/TabBar';

type TopBarProps = {
    onSearch?: (query: string) => void;
    onFavoriteFilter?: (showFavorites: boolean) => void;
    view?: 'list' | 'map';
    onViewChange?: (view: 'list' | 'map') => void;
    variant?: 'default' | 'details';
    onBack?: () => void;
}

const TopBar = ({ onSearch, onFavoriteFilter, view, onViewChange, variant = 'default', onBack }: TopBarProps) => {
    const location = useLocation();
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [showFavorites, setShowFavorites] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const { user: stytchUser } = useStytchUser();
    const [showAuthModalMessage, setShowAuthModalMessage] = useState('Log in');

    const handleFavoriteClick = () => {
        if (stytchUser) {
            setShowFavorites(!showFavorites);
            onFavoriteFilter?.(!showFavorites);
        } else {
            setShowAuthModal(true);
            setShowAuthModalMessage('Log in om te filteren op favorieten');
        }
    };

    const handleAuthClick = () => {
        if (stytchUser) {
            setShowProfileModal(true);
        } else {
            setShowAuthModal(true);
            setShowAuthModalMessage('Aanmelden');
        }
    };

    return (
        <Box sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            height: '60px',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.background.default,
        })}>
            {showSearchInput && onSearch ? (
                <Search onClose={() => setShowSearchInput(false)} onSearch={onSearch} />
            ) : (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
                        {variant === 'default' ? (
                            <IconButton
                                sx={{
                                    width: '60px',
                                    height: '60px',
                                    color: stytchUser ? 'inherit' : 'action.disabled',
                                    '&:hover': {
                                        backgroundColor: stytchUser ? 'action.hover' : 'transparent',
                                    },
                                    display: location.pathname.includes('/events') ? 'flex' : 'none'
                                }}
                                onClick={handleFavoriteClick}
                            >
                                {!showFavorites ? (
                                    <BookmarkBorderIcon />
                                ) : (
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            animation: showFavorites ? 'twistBounce 1s' : 'none',
                                            transform: 'rotate(135deg)',
                                            '@keyframes twistBounce': {
                                                '0%': { transform: 'scale(0) rotateY(0) rotate(135deg)' },
                                                '50%': { transform: 'scale(1.5) rotateY(360deg) rotate(135deg)' },
                                                '100%': { transform: 'scale(1) rotateY(360deg) rotate(135deg)' },
                                            },
                                        }}
                                    >
                                        🔖
                                    </Typography>
                                )}
                            </IconButton>
                        ) : (
                            <IconButton
                                sx={{
                                    width: '60px',
                                    height: '60px',
                                }}
                                onClick={onBack}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        {variant === 'default' && view && onViewChange && (
                            <TabBar view={view} onViewChange={onViewChange} />
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        {variant === 'default' && onSearch && (
                            <IconButton
                                sx={{ color: 'white', padding: 2, display: location.pathname.includes('/events') ? 'flex' : 'none' }}
                                onClick={() => setShowSearchInput(true)}
                            >
                                <SearchIcon />
                            </IconButton>
                        )}
                        <IconButton
                            sx={{ color: 'white', padding: 2 }}
                            onClick={handleAuthClick}
                        >
                            <AccountCircleIcon />
                        </IconButton>
                    </Box>
                </>
            )}
            <AuthModal open={showAuthModal} onClose={() => setShowAuthModal(false)} message={showAuthModalMessage} />
            <ProfileModal open={showProfileModal} onClose={() => setShowProfileModal(false)} />
        </Box>
    );
};

export default TopBar;
