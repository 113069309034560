export const getNextOccurrence = (date: string, recurringDay: number): string => {
    const eventDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const originalHours = eventDate.getHours();
    const originalMinutes = eventDate.getMinutes();

    while (eventDate < today || eventDate.getDay() !== recurringDay) {
        eventDate.setDate(eventDate.getDate() + 1);
    }

    eventDate.setHours(originalHours, originalMinutes);

    return eventDate.toISOString();
};