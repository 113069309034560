import React, { useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useLocation, useNavigate } from 'react-router-dom';

interface NavigationBarProps {
    isAdmin: boolean;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ isAdmin }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isLocationHome = useMemo(() => location.pathname.includes('/events'), [location.pathname]);
    const isLocationCreate = useMemo(() => location.pathname.includes('/create'), [location.pathname]);

    if (!isAdmin) {
        return null;
    }

    return (
        <Box sx={(theme) => ({
            position: 'fixed',
            bottom: 0,
            width: '100%',
            mt: 'auto',
            bgcolor: theme.palette.background.paper,
            p: 1,
            display: 'flex',
            justifyContent: 'space-around',
            zIndex: 999,
            '@media (min-width: 768px)': {
                position: 'fixed',
                flexDirection: 'column',
                top: 0,
                left: 0,
                height: '100%',
                width: '60px',
                justifyContent: 'flex-start',
                p: 1,
                borderTop: 'none',
                borderRight: '1px solid #293038',
            },
            'svg': {
                fontSize: '16pt'
            }
        })}>
            <IconButton onClick={() => navigate('/events')}>
                {isLocationHome ? <HomeIcon /> : <HomeOutlinedIcon sx={(theme) => ({ color: theme.palette.text.secondary })} />}
            </IconButton>
            <IconButton onClick={() => navigate('/create')}>
                {isLocationCreate ? <AddIcon /> : <AddOutlinedIcon sx={(theme) => ({ color: theme.palette.text.secondary })} />}
            </IconButton>
        </Box>
    );
};

export default NavigationBar;
