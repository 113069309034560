import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Event } from '../../types/Event';
import FullScreenLoader from '../loader/FullScreenLoader';
import { User } from '../../types/User';
import DeleteConfirmation from '../delete-confirmation/DeleteConfirmation';
import EventService from '../../services/eventService';
import { useStytchUser } from '@stytch/react';
import EventCard from '../event-card/EventCard';

type EventListProps = {
    loading: boolean;
    eventCardsLoading: boolean;
    searchQuery: string;
    events: Event[];
    selectedKeywords: string[];
    highlightedMeetId: string | null;
    favorites: string[];
    handleFavorite: (id: string) => void;
    showFavorites: boolean;
    user: User | null;
    userLocation?: { lat: number, lng: number } | null;
    onScreenClick: () => void;
    onHighlight: (id: string) => void;
    onEdit: (id: string) => void;
}

const EventList = ({ searchQuery, events, loading, eventCardsLoading, selectedKeywords, user, highlightedMeetId, showFavorites, userLocation, onScreenClick, onHighlight, onEdit, favorites, handleFavorite }: EventListProps) => {
    const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
    const eventService = EventService();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [meetToDelete, setMeetToDelete] = useState<string | null>(null);
    const { user: stytchUser } = useStytchUser();
    const isAdmin = useMemo(() => user?.isAdmin && stytchUser || false, [user, stytchUser]);

    useEffect(() => {
        const filtered = events.filter(event =>
            (event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                event.location.toLowerCase().includes(searchQuery.toLowerCase())) &&
            (selectedKeywords.length === 0 || selectedKeywords.every(kw => event.keywords.includes(kw)))
        );
        setFilteredEvents(filtered);
    }, [searchQuery, selectedKeywords, events]);

    useEffect(() => {
        const scrollToHighlightedMeet = () => {
            if (highlightedMeetId && !loading) {
                const highlightedElement = document.getElementById(`event-${highlightedMeetId}`);
                if (highlightedElement) {
                    setTimeout(() => {
                        highlightedElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        });
                    }, 300);
                }
            }
        };

        scrollToHighlightedMeet();
    }, [highlightedMeetId, loading, events]);

    if (loading) {
        return <FullScreenLoader />;
    }

    if (events.length === 0 || (showFavorites && filteredEvents.filter(event => favorites.includes(event.id)).length === 0)) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 240px)',
                    width: '100%'
                }}
            >
                <Typography sx={{ width: '240px' }} textAlign={'center'} variant="h6" color="text.primary">
                    {showFavorites ? 'Je hebt nog geen favorieten' : 'Er zijn momenteel geen meets beschikbaar'}
                </Typography>
            </Box>
        );
    }

    const renderEventCard = (event: Event) => (
        <Box
            key={event.id}
            id={`event-${event.id}`}
            sx={{
                width: '100%',
                transition: 'filter 0.3s ease-in-out, opacity 0.3s ease-in-out',
                filter: highlightedMeetId && event.id !== highlightedMeetId ? 'blur(2px)' : 'none',
                opacity: highlightedMeetId && event.id !== highlightedMeetId ? 0.7 : 1,
            }}
        >
            <EventCard
                event={event}
                user={user}
                loading={eventCardsLoading}
                isFavorite={favorites.includes(event.id)}
                onFavorite={() => handleFavorite(event.id)}
                onHighlight={(id: string) => onHighlight(id)}
                isHighlighted={event.id === highlightedMeetId}
                onEdit={() => onEdit(event.id)}
                onDelete={() => {
                    setMeetToDelete(event.id);
                    setDeleteConfirmationOpen(true);
                }}
                userLocation={userLocation}
            />
        </Box>
    );

    const handleDeleteConfirmation = async () => {
        if (meetToDelete) {
            try {
                await eventService.deleteEvent(meetToDelete);
                // Remove the deleted meet from the list
                setFilteredEvents(prevEvents => prevEvents.filter(event => event.id !== meetToDelete));
            } catch (error) {
                console.error('Failed to delete meet:', error);
            }
        }
        setDeleteConfirmationOpen(false);
        setMeetToDelete(null);
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    p: 2,
                    position: 'relative',
                    '@media (max-width: 768px)': {
                        marginBottom: isAdmin ? '53px' : '0px',
                    }
                }}
                onClick={(e) => {
                    // Only trigger screen click if clicking the background, not an event card
                    if (e.currentTarget === e.target) {
                        onScreenClick();
                    }
                }}
            >
                {filteredEvents.filter(event => showFavorites ? favorites.includes(event.id) : true).map((event) => renderEventCard(event))}
            </Box>
            <DeleteConfirmation
                type="meet"
                open={deleteConfirmationOpen}
                onClose={() => setDeleteConfirmationOpen(false)}
                onConfirm={handleDeleteConfirmation}
            />
        </>
    );
};

export default EventList;
