import React, { useState, useEffect } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';

interface ImagePreviewModalProps {
    images: string[];
    initialIndex: number;
    open: boolean;
    onClose: () => void;
}

const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({ images, initialIndex, open, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    useEffect(() => {
        if (open) {
            setCurrentIndex(initialIndex);
        }
    }, [open, initialIndex]);

    const handlePrevious = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (currentIndex < images.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    return (
        <Modal
            open={open}
            // onClose={(e) => e.stopPropagation()}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'rgba(0, 0, 0, 0.9)',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        color: 'white',
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {images.length > 1 && (
                    <>
                        {currentIndex > 0 && (
                            <IconButton
                                onClick={handlePrevious}
                                sx={{
                                    position: 'absolute',
                                    left: 16,
                                    color: 'white',
                                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.6)' },
                                }}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        )}
                        {currentIndex < images.length - 1 && (
                            <IconButton
                                onClick={handleNext}
                                sx={{
                                    position: 'absolute',
                                    right: 16,
                                    color: 'white',
                                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.6)' },
                                }}
                            >
                                <ChevronRightIcon />
                            </IconButton>
                        )}
                    </>
                )}

                <Box
                    component="img"
                    src={`${process.env.REACT_APP_API_BASE_URL}${images[currentIndex]}`}
                    sx={{
                        maxWidth: '90%',
                        maxHeight: '90vh',
                        objectFit: 'contain',
                    }}
                />
            </Box>
        </Modal>
    );
};

export default ImagePreviewModal; 