import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardHeader, CardActions, IconButton, Typography, Box } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import IosShareIcon from '@mui/icons-material/IosShare';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatInTimeZone } from 'date-fns-tz';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Event } from '../../types/Event';
import { User } from '../../types/User';
import { useStytchUser } from '@stytch/react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import RoomIcon from '@mui/icons-material/Room';
import AuthModal from '../auth-modal/AuthModal';
import RatingDisplay from '../rating/Rating';
import AttendButton from '../attend-button/AttendButton';
import AttendeesCounter from '../attendees-counter/AttendeesCounter';
import DistanceCounter from '../distance-counter/DistanceCounter';
import { getNextOccurrence } from '../../utils/date-utils';

type EventCardProps = {
    event: Event;
    user: User | null;
    loading: boolean;
    isFavorite: boolean;
    onFavorite: () => void;
    onHighlight: (meetId: string) => void;
    isHighlighted?: boolean;
    onEdit: () => void;
    onDelete: () => void;
    userLocation?: { lat: number, lng: number } | null;
}

const EventCard: React.FC<EventCardProps> = ({
    event,
    user,
    loading,
    isFavorite,
    onFavorite,
    onHighlight,
    isHighlighted = false,
    onEdit,
    onDelete,
    userLocation,
}) => {
    const { id, title: originalTitle, customTitle, date, location, needsAdminAttention, latitude, longitude } = event;
    const displayDate = event?.recurringDay
        ? getNextOccurrence(date, event.recurringDay)
        : date;
    const formattedDate = useMemo(() => {
        const parsedDate = new Date(displayDate);
        return formatInTimeZone(parsedDate, 'Europe/London', 'd MMMM yyyy - HH:mm');
    }, [displayDate]);
    const title = useMemo(() => customTitle || originalTitle, [customTitle, originalTitle]);
    const subTitle = useMemo(() => customTitle && originalTitle ? originalTitle : null, [customTitle, originalTitle]);
    const navigate = useNavigate();
    const [animateFavorite, setAnimateFavorite] = useState(false);
    const [animateShare, setAnimateShare] = useState(false);
    const [animateOptions, setAnimateOptions] = useState(false);
    const [isFavorited, setIsFavorited] = useState(isFavorite);
    const [isSharing, setIsSharing] = useState(false);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const { user: stytchUser } = useStytchUser();
    const isAdmin = useMemo(() => user?.isAdmin && stytchUser, [user, stytchUser]);
    const open = Boolean(anchorEl);
    const [animateMarker, setAnimateMarker] = useState(false);
    const [isAttending, setIsAttending] = useState(user?.attended_meets.includes(id) || false);
    const [attendeesCount, setAttendeesCount] = useState(event.attendeesAmount);

    useEffect(() => {
        setIsFavorited(isFavorite);
    }, [isFavorite]);

    useEffect(() => {
        setAnimateMarker(true);
        const timer = setTimeout(() => setAnimateMarker(false), 3000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        setIsAttending(user?.attended_meets.includes(id) || false);
    }, [user, stytchUser]);

    const handleFavoriteClick = () => {
        if (stytchUser) {
            setAnimateFavorite(false);
            setIsFavorited(!isFavorited);
            setAnimateFavorite(true);
            onFavorite();
        } else {
            setShowAuthModal(true);
        }
    };

    const handleShare = () => {
        onHighlight(event?.id);
        const originalUrl = window.location.pathname;
        const newUrl = `/events/${event?.id}`;
        window.history.pushState(null, '', newUrl);
        setAnimateShare(false);

        if (navigator.share) {
            setAnimateShare(true);
            setIsSharing(true);
            navigator.share({
                url: `${window.location.origin}/events/${event?.id}`,
            })
                .then(() => {
                    setIsSharing(false);
                    onHighlight('');
                    window.history.pushState(null, '', originalUrl);
                })
                .catch((error) => {
                    console.error('Error sharing:', error);
                    setIsSharing(false);
                    onHighlight('');
                    window.history.pushState(null, '', originalUrl);
                });
        } else {
            onHighlight('');
            window.history.pushState(null, '', originalUrl);
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setAnimateOptions(true);
        setIsOptionsOpen(true);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setAnimateOptions(false);
        setIsOptionsOpen(false);
    };

    const handleEditClick = () => {
        onEdit();
        handleMenuClose();
    };

    const handleDeleteClick = () => {
        handleMenuClose();
        onDelete();
    };

    const handleOpenLocation = () => {
        if (latitude !== null && longitude !== null) {
            const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
            window.open(mapsUrl, '_blank');
        } else {
            const encodedAddress = encodeURIComponent(location);
            const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
            window.open(mapsUrl, '_blank');
        }
    };

    const handleOpenRoute = () => {
        if (latitude !== null && longitude !== null) {
            const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
            window.open(mapsUrl, '_blank');
        } else {
            const encodedAddress = encodeURIComponent(location);
            const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
            window.open(mapsUrl, '_blank');
        }
    };

    const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
        const earthRadiusKm = 6371;
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return earthRadiusKm * c;
    };

    const handleCardClick = (e: React.MouseEvent) => {
        // Prevent navigation if clicking on interactive elements
        if (
            e.target instanceof HTMLElement &&
            (e.target.closest('button') ||
                e.target.closest('.leaflet-container') ||
                e.target.closest('[role="menuitem"]') ||
                e.target.closest('[role="menu"]'))
        ) {
            return;
        }
        navigate(`/events/${event.id}`);
    };

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                bgcolor: 'background.paper',
                transition: 'box-shadow 0.3s ease-in-out, border 0.3s ease-in-out',
                boxShadow: isHighlighted
                    ? '0 0 100px 1px rgba(0, 180, 230, 0.2)'
                    : 'none',
                border: needsAdminAttention
                    ? '2px solid yellow'
                    : 'none',
                animation: needsAdminAttention
                    ? 'pulse 1.5s infinite'
                    : 'none',
                '@keyframes pulse': {
                    '0%': { borderColor: 'yellow' },
                    '50%': { borderColor: 'orange' },
                    '100%': { borderColor: 'yellow' },
                },
                cursor: 'pointer',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                },
                WebkitTapHighlightColor: 'transparent',
                '&:active': {
                    backgroundColor: 'background.paper',
                },
            }}
        >
            <Box sx={{ width: '100%' }}>
                {latitude !== null && longitude !== null && (
                    <Box onClick={(e) => {
                        e.stopPropagation();
                        handleOpenLocation();
                    }} sx={{ width: '100%', height: '125px', padding: 1, paddingBottom: 0, flexShrink: 0 }} >
                        <MapContainer
                            center={[latitude, longitude]}
                            zoom={13}
                            zoomControl={false}
                            dragging={false}
                            touchZoom={false}
                            doubleClickZoom={false}
                            scrollWheelZoom={false}
                            attributionControl={false}
                            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                        >
                            <TileLayer
                                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                                detectRetina
                            />
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleOpenLocation();
                                }}
                                sx={{
                                    position: 'absolute',
                                    zIndex: '999',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    animation: animateMarker ? 'bounce 0.5s ease-in-out 3' : 'none',
                                    '@keyframes bounce': {
                                        '0%, 100%': { transform: 'translate(-50%, -50%)' },
                                        '50%': { transform: 'translate(-50%, -70%)' },
                                    },
                                }}
                            >
                                <RoomIcon fontSize='small' />
                            </IconButton>
                            <Box sx={{ position: 'absolute', bottom: 8, right: 8, zIndex: 999, display: 'flex', gap: 1 }}>
                                <RatingDisplay averageRating={event.averageRating} />
                                <AttendeesCounter attendeeCount={attendeesCount} />
                            </Box>
                            <Box sx={{ position: 'absolute', bottom: 8, left: 8, zIndex: 999, display: 'flex', gap: 1 }}>
                                {userLocation && latitude !== null && longitude !== null && (
                                    <DistanceCounter distance={Math.round(calculateDistance(userLocation.lat, userLocation.lng, latitude, longitude))} />
                                )}
                            </Box>
                        </MapContainer>
                    </Box>
                )}
            </Box>
            <Box sx={{ minWidth: '250px', width: '100%', position: 'relative' }}>
                <CardHeader
                    sx={{
                        paddingBottom: 0,
                        paddingTop: 1,
                    }}
                    action={
                        <IconButton
                            aria-label="add to favorites"
                            onClick={handleFavoriteClick}
                            sx={{
                                color: stytchUser ? 'inherit' : 'action.disabled',
                                '&:hover': {
                                    backgroundColor: stytchUser ? 'action.hover' : 'transparent',
                                },
                                height: '40px',
                                width: '40px',
                            }}
                        >
                            {!isFavorited || !stytchUser ? (
                                <BookmarkBorderIcon />
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '16pt',
                                        animation: animateFavorite ? 'twistBounce 1s' : 'none',
                                        transform: 'rotate(135deg)',
                                        '@keyframes twistBounce': {
                                            '0%': { transform: 'scale(0) rotateY(0) rotate(135deg)' },
                                            '50%': { transform: 'scale(1.5) rotateY(360deg) rotate(135deg)' },
                                            '100%': { transform: 'scale(1) rotateY(360deg) rotate(135deg)' },
                                        },
                                    }}
                                >
                                    🔖
                                </Typography>
                            )}
                        </IconButton>
                    }
                    title={
                        <Box onClick={handleCardClick}
                            sx={{
                                fontSize: 0,
                                marginBottom: subTitle ? 0 : 1
                            }}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: title.length > 20 ? '11pt' : title.length > 15 ? '13pt' : '14pt' }} component="span">
                                {title}
                            </Typography>
                            <br />
                            {subTitle && (
                                <Typography sx={{ fontSize: '10pt', fontWeight: 'light' }} component="span">
                                    {subTitle}
                                </Typography>
                            )}
                        </Box>
                    }
                    subheader={
                        <Box>
                            <Box display="flex" alignItems="center">
                                <Typography sx={{ marginRight: 1 }}>📅</Typography>
                                <Typography variant="body2" color="text.secondary">{formattedDate}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography sx={{ marginRight: 1 }}>📍</Typography>
                                <Typography variant="body2" color="text.secondary">{getStreetAddress(location)}</Typography>
                            </Box>

                        </Box>
                    }
                />
                <CardActions disableSpacing sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box>
                        <IconButton
                            aria-label="get directions"
                            onClick={handleOpenRoute}
                            sx={{ height: '40px', width: '40px' }}
                        >
                            <DirectionsIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                        <IconButton
                            aria-label="share"
                            onClick={handleShare}
                            sx={{ height: '40px', width: '40px' }}
                        >
                            {!isSharing ? (
                                <IosShareIcon sx={{ fontSize: '20px' }} />
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '16pt',
                                        animation: animateShare && isSharing ? 'bounceShare 0.3s' : 'none',
                                        '@keyframes bounceShare': {
                                            '0%': { transform: 'perspective(400px) scale(0)' },
                                            '50%': { transform: 'perspective(400px) scale(1.5)' },
                                            '100%': { transform: 'perspective(400px) scale(1)' },
                                        }
                                    }}
                                >
                                    🔗
                                </Typography>
                            )}
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AttendButton
                            meetId={event.id}
                            eventDate={event.recurringDay ? new Date(getNextOccurrence(event.date, event.recurringDay)) : new Date(event.date)}
                            isAttending={isAttending}
                            attendeesCount={attendeesCount}
                            loading={loading}
                            onAttendChange={(newIsAttending, newAttendeesCount) => {
                                setIsAttending(newIsAttending);
                                setAttendeesCount(newAttendeesCount);
                            }}
                        />
                        {(isAdmin) && (
                            <Box sx={{ marginLeft: 2 }}>
                                <IconButton aria-label="more options" onClick={handleMenuOpen}>
                                    {!isOptionsOpen ? (<MoreVertIcon sx={{ fontSize: '20px' }} />
                                    ) : <Typography
                                        sx={{
                                            animation: animateOptions && isOptionsOpen ? 'bounceOptions 0.3s' : 'none',
                                            '@keyframes bounceOptions': {
                                                '0%': { transform: 'perspective(400px) scale(0)' },
                                                '50%': { transform: 'perspective(400px) scale(1.5)' },
                                                '100%': { transform: 'perspective(400px) scale(1)' },
                                            }
                                        }}
                                    >
                                        🛠
                                    </Typography>}
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleMenuClose}
                                >
                                    {isAdmin && (
                                        [
                                            <MenuItem key="edit" onClick={handleEditClick}>Bewerken</MenuItem>,
                                            <MenuItem key="delete" onClick={handleDeleteClick}>Verwijderen</MenuItem>
                                        ]
                                    )}
                                </Menu>
                            </Box>
                        )}
                    </Box>
                </CardActions>
            </Box>
            <AuthModal open={showAuthModal} onClose={() => setShowAuthModal(false)} message={'Log in om te bewaren'} />
        </Card >
    );
};

const getStreetAddress = (fullAddress: string): string => {
    const parts = fullAddress.split(',');
    return parts[0].trim();
};

export default EventCard;
